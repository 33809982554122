.demoitemcontainer {
  display: inline-block;
  position: relative;
  overflow: hidden;
  width: 155px;
  height: 100px;
  cursor: pointer;
  opacity: 0;
}

.demoitemImg {
  width: inherit;
  height: auto;
  position: absolute;
  bottom: 0px;
}
.demoitemText {
  width: calc(100% - 10px);
  height: auto;
  color: white;
  padding: 5px;
  font-size: xx-small;
  font-weight: 400;
  visibility: hidden;
  position: absolute;
  bottom: 0px;
}
.demoitemcontainer:hover img {
  opacity: 0.3;
  transition: 0.5s;
}
.demoitemcontainer:hover p {
  visibility: visible;
  transition: 0.5s;
}
@media (min-width: 360px) {
  .demoitemcontainer {
    width: 170px;
    height: 120px;
  }
  .demoitemText {
    font-size: x-small;
  }
}
@media (min-width: 768px) {
  .demoitemcontainer {
    width: 350px;
    height: 225px;
  }
  .demoitemText {
    font-size: small;
  }
}
