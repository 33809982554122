:root{
    --headercontainercolor:black;
    --aboutcontainercolor:white;
    --democontainercolor:black;
    
}


.appcontainer{
    height: 2000px;
    background-color: whitesmoke;
    position: relative;
}

.leftanim{
    opacity: 0;
    animation: popupleft;
    animation-duration: 1s;
    animation-delay: 0.5s;
    animation-timing-function: ease-in;
    animation-fill-mode: forwards;
}

@keyframes popupleft{
    from {
        transform: translateX(-100px);
        opacity: 0;
      }
    
      to {
        transform: translateX(0px);
        opacity: 1;
      }
}

.rightanim{
    opacity: 0;
    animation: popupright;
    animation-duration: 1s;
    animation-delay: 0.5s;
    animation-timing-function: ease-in;
    animation-fill-mode: forwards;
}
@keyframes popupright{
    from {
        transform: translateX(100px);
        opacity: 0;
      }
    
      to {
        transform: translateX(0px);
        opacity: 1;
      }
}
.appearanim{
    opacity: 0;
    animation: appearing;
    animation-fill-mode: forwards;
    animation-duration: 2s;
    animation-delay: 0.5s;
}
@keyframes appearing{
    from{opacity: 0;}
to{opacity: 1;}
}
/* app.js menubar adjusting to clear emptyspace */
.menubarsdomain{
    position: relative;
    top:-40px;
}
