.demoscontainer{
    background-color: var(--democontainercolor);
    min-height: 100vh;
    padding-top: 50px;
}
.demoheadline{
    margin-top: 70px;
    opacity: 0;
    color: white;
    font-size: x-large;
}

@media(min-width:360px){
    .demoscontainer{
        padding-left: 10px;
    }
}
@media(min-width:380px){
    .demoscontainer{
        padding-left: 20px;
    }
}