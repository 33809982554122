.menubarcontainer{
    position: sticky;
    z-index: 100;
    top: 0;
}
.menuitem{
    display: inline-block;
    margin-left: 30px;
    margin-right: 10px;
    font-size: large;
    font-weight: 600;
    color: white;
}


@media(min-width:450px){
    .menuitem{
    font-size: x-large;
    margin-right: 40px;

    }
}