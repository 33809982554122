.headerContainer{
    background-color: var(--headercontainercolor);
    min-height: 100vh;
    padding:20px;
    position: relative;
}
.headerContainer h1{
    color:white;
    padding-top: 80px;
    text-align: center;
    opacity: 0;
    animation: appear 2s;
    animation-delay: 1s;
    animation-fill-mode: forwards;
}

.rollahead{
    display: flex;
    align-items: center;
    justify-content: center;
    margin: auto;
    margin-top: 100px;
    opacity: 0;
    border-radius: 10px;
    width: 100px;
    height: 40px;
    border: 1px solid;
    color: white;
    animation: appear 2s;
    animation-delay: 12s;
    animation-fill-mode: forwards;
}

.typer{
    padding: 20px;
    padding-top: 30px;
    color:white;
    text-align: center;
  }

  @keyframes appear{
from{opacity: 0;}
to{opacity: 1;}
  }
  @media(min-width:600px){
    .typer{
        font-size: xx-large;
    }
    .headerContainer h1{
        font-size: 70px;
    }

    .rollahead{
        width: 180px;
        height: 80px;
        font-size: x-large;
    }
}