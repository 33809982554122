.skillcontainer{
    background-color: var(--aboutcontainercolor);
    min-height: 100vh;
    padding:20px;
    padding-top: 50px;
}
.skillbase{
    opacity: 0;
    margin:10px;
    padding:10px;
    max-width:600px;
}
.introduceyourself{
    font-size: large;
}
.plainskillitem{
    font-size:35px;
    font-weight: 700;
    display: inline-block;
    width: 60px;
    height: 60px;
    position: relative;
    top:10px;
}
.skilllogo{
    width: 110px;
    padding: 10px;
    display: inline-block;
    vertical-align: bottom;
}

.github:hover {
    width: 150px;
    box-shadow: 5px 7px 5px 1px rgba(0,0,0,0.54);
    cursor: pointer;
    transition: 0.3s ease-out;
}
.github:hover ~ .speechbubble{
    opacity: 1;
}
.speechbubble{
    padding: 10px;
    padding-bottom: 30px;
    width: 250px;
    display: inline-block;
    align-items: center;
    opacity: 0;
    font-size: 20px;
    text-align: center;
    transition: 0.3s ease-out;
}

.skillitem{
    display: inline-block;
    width: 60px;
    height: 60px;
    padding: 10px;
    border-radius: 0px 0px 120px 120px;
    border: 1px solid #000000;
    box-shadow: 5px 7px 5px 1px rgba(0,0,0,0.54);
    text-align: center;
    font-size: 15px;
    font-weight: 600;
}
.js{
    background: linear-gradient(to right, rgba(209,182,92,1) 0%, rgba(85,83,43,1) 48%, 
    rgba(182,153,47,1) 57%, rgba(203,175,72,1) 100%);
}
.ts{
    background: linear-gradient(to right, rgba(39,107,190,1) 0%, rgba(39,107,190,1) 50%,
     rgba(65,140,216,1) 55%, rgba(65,140,216,1) 100%);
}
.css{
    background: linear-gradient(to right, rgba(152,97,204,1) 0%, rgba(101,0,168,1) 45%, 
    rgba(141,71,194,1) 52%, rgba(160,56,220,1) 100%);
}
.html{
    background: linear-gradient(to right, rgba(244,52,42,1) 0%, rgba(237,16,12,1) 48%,
     rgba(237,89,84,1) 56%, rgba(228,33,36,1) 100%);
}
.fav{
    width: 70px;
    height: 70px;
    display: inline-block;
    vertical-align: bottom;
}
.skillflexcontainer{
    margin-left: 30px;
    width: 150px;
    overflow: hidden;
}
.skillflex{
    white-space: nowrap;
    animation:textcurrent;
    animation-duration: 30s;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
}
@keyframes textcurrent{
    from {
        transform: translateX(150px);
      }
    
      to {
        transform: translateX(-1300px);
      }
}

